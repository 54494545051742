export const TOKEN_INVALID_EVENT = "TOKEN_INVALID_EVENT";

export const SHOP_CHANGE_EVENT = "SHOP_CHANGE_EVENT";

export const UPDATE_USER_ASSET_EVENT = "UPDATE_USER_ASSET_EVENT";

export const UPDATE_MY_REWARD_EVENT = "UPDATE_MY_REWARD";

export const THIRD_PLATFORM_LOGIN_COMPLETED = "THIRD_PLATFORM_LOGIN_COMPLETED";

export const LOGIN_COMPLETED = "LOGIN_COMPLETED";
