import { createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  class: "page-app-main safe-bottom"
};
var _hoisted_2 = {
  key: 0,
  class: "page-app-main_footer"
};
import { useRouter } from "@/hooks/useRouter";
import Navbar from "./Navbar.vue";
export default {
  __name: 'Page',
  props: {
    title: {
      type: String,
      default: ""
    },
    showBack: {
      type: Boolean,
      default: true
    },
    showFooter: {
      type: Boolean,
      default: false
    },
    isYellowBg: {
      type: Boolean,
      default: false
    },
    onBack: {
      type: Function,
      default: null
    }
  },
  setup: function setup(__props) {
    var _useRouter = useRouter(),
      routerBack = _useRouter.routerBack,
      routerPush = _useRouter.routerPush;
    var props = __props;
    var backHandler = function backHandler() {
      if (props.onBack) {
        // 如果传入了onBack,则执行onBack
        props.onBack();
      } else {
        routerBack();
      }
    };
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("section", _hoisted_1, [_createVNode(Navbar, {
        id: "page-nav-bar",
        title: __props.title,
        "show-back": __props.showBack,
        onBack: backHandler
      }, null, 8, ["title", "show-back"]), _createElementVNode("div", {
        class: _normalizeClass(["page-app-main_content", {
          'yellow-bg': __props.isYellowBg
        }])
      }, [_renderSlot(_ctx.$slots, "default")], 2), __props.showFooter ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_renderSlot(_ctx.$slots, "footer")])) : _createCommentVNode("", true)]);
    };
  }
};