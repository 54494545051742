import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
import _imports_0 from '@/assets/images/back.svg';
var _hoisted_1 = {
  key: 0,
  src: _imports_0,
  class: "arrow"
};
export default {
  __name: 'Navbar',
  props: {
    title: {
      type: String,
      default: ""
    },
    showBack: {
      type: Boolean,
      default: true
    }
  },
  emits: ["back"],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var emit = __emit;
    var onBack = function onBack() {
      emit("back");
    };
    return function (_ctx, _cache) {
      var _component_van_nav_bar = _resolveComponent("van-nav-bar");
      return _openBlock(), _createBlock(_component_van_nav_bar, {
        class: "nav-bar",
        title: __props.title,
        fixed: "",
        onClickLeft: onBack
      }, {
        left: _withCtx(function () {
          return [__props.showBack ? (_openBlock(), _createElementBlock("img", _hoisted_1)) : _createCommentVNode("", true)];
        }),
        _: 1
      }, 8, ["title"]);
    };
  }
};