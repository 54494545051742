import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue";
var _hoisted_1 = {
  class: "dishes-order-customer-card"
};
var _hoisted_2 = {
  class: "status"
};
var _hoisted_3 = {
  class: "left"
};
var _hoisted_4 = {
  class: "tag"
};
var _hoisted_5 = {
  class: "name"
};
var _hoisted_6 = {
  key: 0
};
var _hoisted_7 = {
  key: 1
};
var _hoisted_8 = {
  class: "sta"
};
import { DishOrderTypeMap, DishesOrderStatusMap } from "@/constant/order";
export default {
  __name: 'DishesOrderCustomerCard',
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  setup: function setup(__props) {
    var props = __props;
    return function (_ctx, _cache) {
      var _props$order$table, _props$order$table2;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(_unref(DishOrderTypeMap)[__props.order.type]), 1), _createElementVNode("div", _hoisted_5, [(_props$order$table = __props.order.table) !== null && _props$order$table !== void 0 && _props$order$table.table_num ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_createElementVNode("span", null, _toDisplayString((_props$order$table2 = __props.order.table) === null || _props$order$table2 === void 0 ? void 0 : _props$order$table2.table_num), 1), __props.order.pick_up_code ? (_openBlock(), _createElementBlock("span", _hoisted_6, " (#" + _toDisplayString(__props.order.pick_up_code) + ")", 1)) : _createCommentVNode("", true)], 64)) : (_openBlock(), _createElementBlock("span", _hoisted_7, " #" + _toDisplayString(__props.order.pick_up_code), 1))])]), _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(DishesOrderStatusMap)[__props.order.status] || __props.order.status), 1)])]);
    };
  }
};