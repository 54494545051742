export const ICDocTypeEnum = {
  REGISTRATION_NO: "registration_no",
  IC_NO: "ic_no",
  PASSPORT_NO: "passport_no",
};

export const ICDocTypeOptions = [
  { label: "Registration No.", value: ICDocTypeEnum.REGISTRATION_NO },
  { label: "IC No.", value: ICDocTypeEnum.IC_NO },
  { label: "Passport No.", value: ICDocTypeEnum.PASSPORT_NO },
];

export const InvoiceStatusEnum = {
  UNDER_REVIEW: "under_review",
  INVOICED: "invoiced",
  VOIDED: "voided",
};

export const InvoiceStatusTextMap = {
  [InvoiceStatusEnum.UNDER_REVIEW]: "Processing",
  [InvoiceStatusEnum.INVOICED]: "Invoiced",
  [InvoiceStatusEnum.VOIDED]: "Cancelled",
};
