import { Persistent } from "@/utils/cache/persistent";
import { post, get } from "@/utils/requestUtil";

export function getUserAddressList(data) {
  return post(`/crm/customer/customer/c/v1/delivery_address/get`, data);
}

export function addUserAddress(data) {
  return post("/crm/customer/customer/c/v1/delivery_address/add", data);
}

export function deleteUserAddress(data) {
  return post(`/crm/customer/customer/c/v1/delivery_address/del`, data);
}

export function updateUserAddress(data) {
  return post("/crm/customer/customer/c/v1/delivery_address/update", data);
}

// 把一个地址设为默认
export function setDefaultToAddress(data) {
  return post(`/crm/customer/customer/c/v1/delivery_address/set_default`, data);
}

export async function getAllCountry() {
  const key = "all_country_code_key";
  const localValue = Persistent.getLocal(key);
  // 20241223 新增津巴布韦的项
  const zwItem = localValue?.find((item) => item.country_code === "ZW");
  if (localValue && zwItem) {
    return Persistent.getLocal(key);
  }
  const res = await get("/base/config/all_countries", {
    filter: "mobile_verification",
  });
  if (res.isOK && res.data.countries.length) {
    Persistent.setLocal(key, res.data.countries);
    return res.data.countries;
  }
  return [];
}
