import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/images/icons/member_code.svg';
import _imports_1 from '@/assets/images/icons/close_btn_black.svg';
var _hoisted_1 = {
  class: "info-container"
};
var _hoisted_2 = {
  class: "info"
};
var _hoisted_3 = {
  class: "info-avatar"
};
var _hoisted_4 = {
  class: "info-name"
};
var _hoisted_5 = {
  class: "info-phone"
};
import Confirm from "@/components/dialog/src/Confirm.vue";
import accountAvatar from "@/assets/images/default_avatar.png";
import Image from "@/components/base/Image.vue";
import { ref, computed } from "vue";
import { useUserStore } from "@/store/userStore.js";
import { useSupplierStore } from "@/store/supplierStore.js";
import { useRouter } from "@/hooks/useRouter.js";
import { clearAuthInfo } from "@/utils/authUtil";
export default {
  __name: 'AccountInfo',
  setup: function setup(__props) {
    var userStore = useUserStore();
    var supplierStore = useSupplierStore();
    var _useRouter = useRouter(),
      routerPush = _useRouter.routerPush;
    var showLogout = ref(false);
    var accountImgUrl = computed(function () {
      var _supplierStore$getSup;
      return (_supplierStore$getSup = supplierStore.getSupplierImageSetting) === null || _supplierStore$getSup === void 0 || (_supplierStore$getSup = _supplierStore$getSup.account_page_image) === null || _supplierStore$getSup === void 0 ? void 0 : _supplierStore$getSup.image_url;
    });
    var telOrEmailStr = computed(function () {
      // 等待添加email
      return userStore.user.telephone || userStore.user.email;
    });
    function handleLogout() {
      clearAuthInfo();
      showLogout.value = false;
      routerPush({
        name: "Login"
      });
      userStore.clearInfo();
    }
    function handleQr() {
      routerPush({
        name: "MemberQrCode"
      });
    }
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        class: "account-info-bg",
        style: _normalizeStyle("background-image:url(".concat(accountImgUrl.value || require("@/assets/images/home/account_page_image_default.png"), ")"))
      }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(Image, {
        class: "imgStyle",
        src: _unref(userStore).user.avatar ? _unref(userStore).user.avatar : _unref(accountAvatar),
        "oss-stylename": "type_5"
      }, null, 8, ["src"]), _createElementVNode("div", null, [_createElementVNode("div", _hoisted_4, _toDisplayString(_unref(userStore).user.nickname), 1), _createElementVNode("div", _hoisted_5, _toDisplayString(telOrEmailStr.value), 1)])]), _createElementVNode("div", {
        class: "info-code",
        onClick: handleQr
      }, _cache[2] || (_cache[2] = [_createElementVNode("img", {
        class: "code",
        src: _imports_0,
        alt: ""
      }, null, -1), _createElementVNode("div", {
        class: "member"
      }, "Member QR", -1)]))])]), _createElementVNode("div", {
        class: "icon-close-wrap",
        onClick: _cache[0] || (_cache[0] = function ($event) {
          return showLogout.value = true;
        })
      }, _cache[3] || (_cache[3] = [_createElementVNode("img", {
        class: "icon-close",
        src: _imports_1,
        alt: ""
      }, null, -1)])), _createVNode(Confirm, {
        visible: showLogout.value,
        "onUpdate:visible": _cache[1] || (_cache[1] = function ($event) {
          return showLogout.value = $event;
        }),
        title: "Logout",
        desc: "Are you sure you want to logout?",
        "ok-text": "Yes",
        "cancel-text": "No",
        "on-ok": handleLogout,
        "on-cancel": function onCancel() {
          return showLogout.value = false;
        },
        "cancel-on-click-overlay": ""
      }, null, 8, ["visible", "on-cancel"])], 4);
    };
  }
};