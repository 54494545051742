export const OrderTypeEnum = {
  SELF_PICK_UP: "self_pick_up",
  SELF_DELIVERY: "self_delivery",
};

export const DisheOrderTypeEnum = {
  DINE_IN: "in-store", // 堂食
  TAKEAWAY: "take-away", // 打包
  DELIVERY: "merchant-delivered", // 外卖
  GRABFOOD: "grabfood",
  ONLINE: "online", // 线上订单
};

export const DishOrderTypeMap = {
  [DisheOrderTypeEnum.DINE_IN]: "Dine-in",
  [DisheOrderTypeEnum.TAKEAWAY]: "Takeaway",
  [DisheOrderTypeEnum.DELIVERY]: "Delivery",
  [DisheOrderTypeEnum.ONLINE]: "Online",
};

export const DishesOrderStatus = {
  // pos 类型的店内订单状态
  UNPAID: "UNPAID", // 未支付
  PAID: "PAID", // 已支付
  REFUNDED: "REFUNDED", // 已退款
  // e-menu 类型的店内订单状态
  UNCONFIRMED: "UNCONFIRMED", // 待确认
  CONFIRMED: "CONFIRMED", // 已确认
  IN_DELIVERY: "IN_DELIVERY", // 配送中
  ABNORMAL: "ABNORMAL", // 异常
  IN_DELIVERY_FAIL: "IN_DELIVERY_FAIL", // 配送失败
  DELIVERY_FAIL: "DELIVERY_FAIL", // 配送失败
  // 两种类型共有的订单状态
  CANCELLED: "CANCELLED", // 已取消
};

export const DishesOrderStatusMap = {
  [DishesOrderStatus.PAID]: "Paid",
};

export const OrderStatusEnum = {
  TO_PAY: "to_pay",
  TO_ACCEPT: "to_accept",
  PREPARING: "preparing",
  READY: "ready",
  COMPLETED: "completed",
  OUT_OF_DELIVERY: "out_of_delivery",
  CANCELLED: "cancelled",
  TO_DELIVER_CANCELLED: "to_deliver_cancelled",
  DELIVERING_CANCELLED: "delivering_cancelled",
  READY_CANCELLED: "ready_cancelled",
  REJECT_CANCELLED: "reject_cancelled",
  REFUNDED: "refunded",
};

export const OrderTypeLabelMap = {
  "dine-in": "Dine-in",
  takeaway: "Takeaway",
  "self-pickup": "Self-Pick up",
  "self-delivery": "Self-delivery",
};

export const CouponAvailableOrderTypeMap = {
  [OrderTypeEnum.SELF_PICK_UP]: "self-pickup",
  [OrderTypeEnum.SELF_DELIVERY]: "self-delivery",
};

export const paymentMethodMap = {
  cash_on_delivery: "Cash on delivery",
  online_payment: "Online Payment",
  online: "Online Payment",
  balance: "Balance",
};

export const PaymentMethod = {
  OFFLINE: "offline", // 线下支付
  ONLINE: "online", // 线上支付
  PAY_AT_CASHIER: "pay_at_cashier",
  ONLINE_PAYMENT: "online_payment",
  CASH: "cash", // 现金
  CREDIT_CARD: "credit_card", // 信用卡
  CASH_ON_DELIVERY: "cash_on_delivery", // 货到付款
  BALANCE: "balance", // 余额
};

export const PaymentMethodMapper = {
  [PaymentMethod.OFFLINE]: "Pay at Cashier",
  [PaymentMethod.ONLINE]: "Online payment",
  [PaymentMethod.PAY_AT_CASHIER]: "Pay at Cashier",
  [PaymentMethod.ONLINE_PAYMENT]: "Online payment",
  [PaymentMethod.CASH]: "Cash",
  [PaymentMethod.CREDIT_CARD]: "Credit card",
  [PaymentMethod.CASH_ON_DELIVERY]: "Cash on delivery",
};
