import { renderSlot as _renderSlot, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { ref } from "vue";
import SelectTaxpayerPopup from "@/components/invoice/SelectTaxpayerPopup.vue";
import InvoiceAddPopup from "@/components/invoice/InvoiceAddPopup.vue";
import InvoiceDetailPopup from "@/components/invoice/InvoiceDetailPopup.vue";
import { useUserStore } from "@/store/userStore";
import { isLogin } from "@/utils/authUtil";
export default {
  __name: 'InvoiceTrigger',
  props: {
    orderId: {
      type: String,
      default: ""
    },
    shopId: {
      type: String,
      default: ""
    },
    recordId: {
      type: String,
      default: ""
    },
    orderType: {
      type: String,
      default: ""
    }
  },
  emits: "success",
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var userStore = useUserStore();
    var showSelectTaxpayerPopup = ref(false);
    var showInvoiceAddPopup = ref(false);
    var showInvoiceDetailPopup = ref(false);
    var emit = __emit;
    var props = __props;
    var onClick = function onClick() {
      if (!!props.recordId) {
        showInvoiceDetailPopup.value = true;
        return;
      }
      var taxPayerList = userStore.getTaxpayerList;
      if (taxPayerList.length > 0) {
        showSelectTaxpayerPopup.value = true;
      } else {
        showInvoiceAddPopup.value = true;
      }
    };
    var onAdd = function onAdd() {
      showInvoiceAddPopup.value = true;
    };
    var onReSubmit = function onReSubmit() {
      var taxPayerList = userStore.getTaxpayerList;
      if (isLogin() && taxPayerList.length > 0) {
        showSelectTaxpayerPopup.value = true;
      } else {
        showInvoiceAddPopup.value = true;
      }
    };
    var onSuccess = function onSuccess(data) {
      if (showInvoiceDetailPopup.value) {
        showInvoiceDetailPopup.value = false;
      }
      emit("success", data);
    };
    var onCancelSuccess = function onCancelSuccess() {
      emit("success");
    };
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        class: "invoice-trigger-container",
        onClick: _cache[3] || (_cache[3] = _withModifiers(function () {}, ["stop"]))
      }, [_createElementVNode("div", {
        class: "invoice-trigger",
        onClick: _withModifiers(onClick, ["stop"])
      }, [_renderSlot(_ctx.$slots, "default")]), _createVNode(SelectTaxpayerPopup, {
        show: showSelectTaxpayerPopup.value,
        "onUpdate:show": _cache[0] || (_cache[0] = function ($event) {
          return showSelectTaxpayerPopup.value = $event;
        }),
        "order-id": __props.orderId,
        "shop-id": __props.shopId,
        "order-type": __props.orderType,
        onAdd: onAdd,
        onSuccess: onSuccess
      }, null, 8, ["show", "order-id", "shop-id", "order-type"]), _createVNode(InvoiceAddPopup, {
        show: showInvoiceAddPopup.value,
        "onUpdate:show": _cache[1] || (_cache[1] = function ($event) {
          return showInvoiceAddPopup.value = $event;
        }),
        "order-id": __props.orderId,
        "shop-id": __props.shopId,
        "order-type": __props.orderType,
        onSuccess: onSuccess
      }, null, 8, ["show", "order-id", "shop-id", "order-type"]), _createVNode(InvoiceDetailPopup, {
        show: showInvoiceDetailPopup.value,
        "onUpdate:show": _cache[2] || (_cache[2] = function ($event) {
          return showInvoiceDetailPopup.value = $event;
        }),
        "record-id": __props.recordId,
        onSuccess: onCancelSuccess,
        onSubmit: onReSubmit
      }, null, 8, ["show", "record-id"])]);
    };
  }
};