import { getSupplyId } from "@/utils/authUtil";
import { post, get } from "@/utils/requestUtil";

export function getInvoiceRecordList(params) {
  return post(`/crm/v1/invoice/c/record/list`, params, {
    apiSecret: process.env.VUE_APP_CRM_API_SECRET,
  });
}

export function getInvoiceRecordDetail(id) {
  return post(
    `/crm/v1/invoice/c/record/detail`,
    {
      invoice_record_id: id,
    },
    {
      apiSecret: process.env.VUE_APP_CRM_API_SECRET,
    }
  );
}

export function issueInvoice(params) {
  return post(`/crm/v1/invoice/c/issue`, params, {
    apiSecret: process.env.VUE_APP_CRM_API_SECRET,
  });
}

export function cancelInvoice(params) {
  return post(`/crm/v1/invoice/c/cancel_issue`, params, {
    apiSecret: process.env.VUE_APP_CRM_API_SECRET,
  });
}

export function updateTaxpayerInfo(params) {
  return post(`/crm/v1/crm_user/c/taxpayer/update`, params, {
    apiSecret: process.env.VUE_APP_CRM_API_SECRET,
  });
}

export function getInvoiceSetting() {
  return get(
    `/orderpin/merchant_operation/v1/operate_setting/c/e_invoice_setting/company/${getSupplyId()}`,
    {},
    {
      apiSecret: process.env.VUE_APP_ORDERPIN_API_SECRET,
    }
  );
}

export function associateInvoice(params) {
  return post(`/crm/v1/invoice/c/associate`, params, {
    apiSecret: process.env.VUE_APP_CRM_API_SECRET,
  });
}
