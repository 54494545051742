export const PriceStrCurrencySymbolEnum = {
  MYR: "RM ",
  VND: " ₫",
  THB: "฿ ",
  IDR: "Rp ",
  TRY: " TL",
  PHP: "₱ ",
  USD: "$ ",
  default: "",
};

export const CountryCurrencySymbolEnum = {
  MY: "MYR",
  VN: "VND",
  TH: "THB",
  ID: "IDR",
  TR: "TRY",
  PH: "PHP",
};
