import axios from "axios";
import { showToast } from "./toastUtil";
import { OK, TOKEN_INVALID, whiteListcode } from "@/constant/responseCode";
import { getToken, isLogin, clearAuthInfo, getUserId } from "./authUtil";
import { useEventBus } from "@vueuse/core";
import { TOKEN_INVALID_EVENT } from "@/constant/eventKey";
import { getUid, buildPublicParams, genUid } from "./tools";
import { useShopStoreWithOut } from "@/store/shopStore";
import { Notify } from "./notifyUtil";
import router from "@/router";
import Dialog from "@/components/dialog/dialog";
import { useTracker } from "@/hooks/useTracker";
import { isObject } from "./isUtil";

const bus = useEventBus(TOKEN_INVALID_EVENT);
const service = axios.create({
  baseURL: process.env.VUE_APP_API_URI,
  // withCredentials: false, // send cookies when cross-domain requests
  timeout: 60000, // request timeout
});

service.interceptors.request.use(
  (config) => {
    const shopStore = useShopStoreWithOut();
    const accessToken = getToken();
    if (accessToken) {
      // let each request carry token
      // please modify it according to the actual situation
      config.headers.Authorization = "Bearer " + accessToken;
    }
    const commonParams = buildPublicParams(
      {
        di: getUid(),
        pn: "tea-h5",
        vn: "0.1.0",
        sv: navigator.userAgent.split(" ")[0],
        pf: "h5",
        ui: getUserId() || "",
        ts: Date.now(),
        tz: -1 * (new Date().getTimezoneOffset() / 60),
        cc: shopStore.getCountry || "unknown",
        // ul: window.navigator.languages[0]?.slice(0, 2)?.toLowerCase(),
        ul: "en", // 暂时固定传en
        rs: `${window.screen?.width}x${window.screen?.height}`,
      },
      { apiSecret: config.apiSecret }
    );
    config.headers = {
      ...commonParams,
      ...config.headers,
      ["x-tid"]: genUid(),
    };

    return config;
  },
  (error) => {
    return Promise.reject({
      errMsg: error || "404(TimeOut)",
      errCode: 404,
    });
  }
);

function request({ url, method, params, data, cancelToken, apiSecret }) {
  return new Promise((reslove) => {
    service({
      url,
      method,
      params,
      data,
      cancelToken,
      apiSecret,
    })
      .then((res) => {
        res.data.isOK = res.data.code === OK;
        if (res.data.code === TOKEN_INVALID) {
          setTimeout(() => {
            if (!isLogin()) return;
            clearAuthInfo();
            Dialog.confirm({
              title: "User Information Error",
              desc: "Please login again",
              okText: "Login",
              withoutCancel: true,
              onOk() {
                router.push({
                  name: "Login",
                });
              },
            });
          }, 60);
        } else if (!whiteListcode.includes(res.data.code)) {
          setTimeout(() => {
            showToast(res.data.msg);
          }, 60);
        }
        reslove(res.data);
      })
      .catch((e) => {
        if (!e) {
          reslove({
            isOK: false,
            msg: "unknown error",
          });
          return;
        }
        const tracker = useTracker();
        if (e.code === "ERR_CANCELED") return;
        if (e?.response?.status === 401) {
          if (isLogin()) {
            showToast("Login has expired, please log in again");
            clearAuthInfo();
            router.push({
              name: "Login",
            });
          }
          reslove({
            isOK: false,
            msg: "Login expired",
          });
          return;
        }
        if (e?.response?.status === 500 || e?.response?.status === 400) {
          if (isObject(e?.response?.data)) {
            Notify.error(e?.response?.data.msg);
          } else {
            Notify.error(e.message);
          }
        }
        reslove({
          isOK: false,
          msg: e.message,
        });
        tracker?.sendErrorReport({
          code: e.code,
          message: e.message,
          url: e.config?.url,
          status: e.response?.status,
          responseCode: e.response?.data?.code,
          responseMsg: e.response?.data?.msg,
        });
      });
  });
}

export function post(url, data = {}, options = {}) {
  return request({
    method: "POST",
    url,
    data,
    ...options,
  });
}

export function get(url, params = {}, options = {}) {
  return request({
    method: "GET",
    url,
    params,
    ...options,
  });
}
