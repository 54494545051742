import { getSupplyId, getUserId } from "@/utils/authUtil";
import { post, get } from "@/utils/requestUtil";

export function getHistoryOrders(params, cancelToken) {
  params.supplier_id = getSupplyId();
  params.user_id = getUserId();
  return post("/orderpin/delivery/c/v1/user/order_list", params, {
    cancelToken,
  });
}

export function getOrderDetail(orderId) {
  return get(`/orderpin/delivery/c/v1/order/detail/${orderId}`);
}

export function getDishesOrderDetail(orderId) {
  return get(`/orderpin/order/c/v3/order/detail/${orderId}`);
}

// 创建订单
export function createDeliveryOrder(params) {
  return post("/orderpin/delivery/c/v2/order/create", {
    ...params,
    channel: "mini_program",
  });
}

// 获取在线支付方式
export function getPaymentMethodList(params = {}) {
  return post(
    // `${process.env.VUE_APP_PAYMENT_API_URI}/payment/v1/config/payment_method/list`,
    `${process.env.VUE_APP_PAYMENT_API_URI}/payment/v2/config/payment_method/list`,
    {
      client_app_id: process.env.VUE_APP_PAYMENT_CONFIG_ID,
      ...params,
    }
  );
}

// 自提自配送订单试算
export function calcDeliveryOrderAmount(params) {
  return post("/orderpin/delivery/c/v2/order/calculate_amount", {
    ...params,
    channel: "mini_program",
  });
}

// 创建在线支付
export function createDeliveryOrderOnlinePay(params) {
  return post("/orderpin/delivery/c/v1/payment/online/create", params);
}

// 重定向
export function goRedirect(params) {
  return get(
    `${process.env.VUE_APP_PAYMENT_API_URI}/payment/v1/callback/redirection?url=` +
      params.url
  );
}

// 查询在线支付结果
export function queryOnlinePayment(params) {
  return post("/o2oms/dishes_order/v2/online_pay/query", params);
}

// 店铺进行中订单统计
export function inProcssOrderStatics(params) {
  return get("/orderpin/delivery/c/v1/order/in_process/statistic", params);
}
