import "core-js/modules/es.array.concat.js";
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives } from "vue";
import _imports_0 from '@/assets/images/right_arrow_gray.svg';
var _hoisted_1 = {
  class: "account-features"
};
var _hoisted_2 = {
  class: "account-features-list"
};
var _hoisted_3 = ["onClick"];
var _hoisted_4 = {
  class: "left"
};
var _hoisted_5 = {
  class: "item-title"
};
import ProfilePopup from "@/components/me/ProfilePopup.vue";
import PontHistoryPoupup from "@/components/points/PontHistoryPoupup.vue";
import InvoiceRecordPopup from "@/components/invoice/InvoiceRecordPopup.vue";
import SvgIcon from "@/components/base/SvgIcon.vue";
import { ref } from "vue";
import { useSupplierStore } from "@/store/supplierStore";
import { computed } from "vue";
export default {
  __name: 'AccountFeature',
  setup: function setup(__props) {
    var supplierStore = useSupplierStore();
    var showProfile = ref(false);
    var showPointHistory = ref(false);
    var showMyInvoice = ref(false);
    var featureList = computed(function () {
      return [{
        icon: "point",
        title: "Points History",
        handler: function handler() {
          return showPointHistory.value = true;
        },
        show: true
      }, {
        icon: "profile",
        title: "My Profile",
        handler: function handler() {
          return showProfile.value = true;
        },
        show: true
      }, {
        icon: "invoice",
        title: "My E-invoice",
        handler: function handler() {
          return showMyInvoice.value = true;
        },
        show: supplierStore.getInvoiceSelfServiceOne()
      }, {
        icon: "phone",
        title: "Live Chat",
        handler: toLiveChat,
        show: supplierStore.getWhatsappid
      }];
    });
    function toLiveChat() {
      window.location.href = "https://wa.me/".concat(supplierStore.getWhatsappid, "/?text=").concat(encodeURIComponent("Live Chat"));
    }
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_cache[4] || (_cache[4] = _createElementVNode("div", {
        class: "account-features-title"
      }, "Features", -1)), _createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(featureList.value, function (item, i) {
        return _withDirectives((_openBlock(), _createElementBlock("div", {
          key: i,
          class: "account-features-item van-haptics-feedback",
          onClick: function onClick($event) {
            var _item$handler;
            return (_item$handler = item.handler) === null || _item$handler === void 0 ? void 0 : _item$handler.call(item);
          }
        }, [_createElementVNode("div", _hoisted_4, [_createVNode(SvgIcon, {
          name: item.icon,
          size: 28,
          class: "item-img"
        }, null, 8, ["name"]), _createElementVNode("div", _hoisted_5, _toDisplayString(item.title), 1)]), _cache[3] || (_cache[3] = _createElementVNode("img", {
          class: "icon",
          src: _imports_0,
          alt: ""
        }, null, -1))], 8, _hoisted_3)), [[_vShow, item.show]]);
      }), 128))]), _createVNode(ProfilePopup, {
        show: showProfile.value,
        "onUpdate:show": _cache[0] || (_cache[0] = function ($event) {
          return showProfile.value = $event;
        })
      }, null, 8, ["show"]), _createVNode(PontHistoryPoupup, {
        show: showPointHistory.value,
        "onUpdate:show": _cache[1] || (_cache[1] = function ($event) {
          return showPointHistory.value = $event;
        })
      }, null, 8, ["show"]), _createVNode(InvoiceRecordPopup, {
        show: showMyInvoice.value,
        "onUpdate:show": _cache[2] || (_cache[2] = function ($event) {
          return showMyInvoice.value = $event;
        })
      }, null, 8, ["show"])]);
    };
  }
};