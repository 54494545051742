import { createApp } from "vue";
import ActivityConfirm from "./ActivityConfirm.vue";
let parent = null;

class Dialog {
  // 实例记录
  static confirmInstance = null;

  // 调用方法
  static confirm(option) {
    if (!parent) {
      parent = document.createElement("div");
      document.body.appendChild(parent);
    }

    if (this.confirmInstance) {
      this.confirmInstance.unmount();
    }
    const app = createApp(ActivityConfirm, {
      visible: true,
      showAfterMounted: true,
      title: option.title,
      desc: option.desc,
      onOk: option.onOk,
      onCancel: option.onCancel,
      okText: option.okText,
      cancelText: option.cancelText,
      withoutCancel: option.withoutCancel,
      showBottomButton: option.showBottomButton,
      disableOkButton: option.disableOkButton,
      closeOnClickOverlay: option.closeOnClickOverlay,
    });

    app.mount(parent);
    this.confirmInstance = app;
  }
}

export default Dialog;
