import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "order-detail-card"
};
var _hoisted_2 = {
  class: "amount-info"
};
var _hoisted_3 = {
  class: "line"
};
var _hoisted_4 = {
  class: "value"
};
var _hoisted_5 = {
  class: "line"
};
var _hoisted_6 = {
  class: "value"
};
var _hoisted_7 = {
  class: "line"
};
var _hoisted_8 = {
  class: "value"
};
var _hoisted_9 = {
  class: "line"
};
var _hoisted_10 = {
  class: "value"
};
var _hoisted_11 = {
  class: "line"
};
var _hoisted_12 = {
  class: "value van-multi-ellipsis--l2"
};
import { DishOrderTypeMap, PaymentMethodMapper } from "@/constant/order";
import { formatDateWithTZ } from "@/utils/format";
export default {
  __name: 'DishesOrderDetailCard',
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  setup: function setup(__props) {
    var props = __props;
    return function (_ctx, _cache) {
      var _props$order$times, _props$order$payment;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_cache[5] || (_cache[5] = _createElementVNode("div", {
        class: "header"
      }, "Order Details", -1)), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_cache[0] || (_cache[0] = _createElementVNode("div", {
        class: "label"
      }, "Order ID", -1)), _createElementVNode("div", _hoisted_4, _toDisplayString(__props.order.id), 1)]), _createElementVNode("div", _hoisted_5, [_cache[1] || (_cache[1] = _createElementVNode("div", {
        class: "label"
      }, "Order Time", -1)), _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(formatDateWithTZ)((_props$order$times = __props.order.times) === null || _props$order$times === void 0 ? void 0 : _props$order$times.create_at)), 1)]), _createElementVNode("div", _hoisted_7, [_cache[2] || (_cache[2] = _createElementVNode("div", {
        class: "label"
      }, "Order Type", -1)), _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(DishOrderTypeMap)[__props.order.type]), 1)]), _createElementVNode("div", _hoisted_9, [_cache[3] || (_cache[3] = _createElementVNode("div", {
        class: "label"
      }, "Payment Method", -1)), _createElementVNode("div", _hoisted_10, _toDisplayString(_unref(PaymentMethodMapper)[(_props$order$payment = __props.order.payment) === null || _props$order$payment === void 0 ? void 0 : _props$order$payment.payment_method]), 1)]), _createElementVNode("div", _hoisted_11, [_cache[4] || (_cache[4] = _createElementVNode("div", {
        class: "label"
      }, "Remarks", -1)), _createElementVNode("div", _hoisted_12, _toDisplayString(__props.order.remark || "-"), 1)])])]);
    };
  }
};