const weeks = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];

export function getDayStr(d) {
  return weeks[d.getDay()];
}

export function getNextDates(days) {
  return Array.from({ length: days }, (v, i) => i).map((day) => {
    const date = new Date();
    date.setDate(date.getDate() + day);
    return {
      date: date,
      week: weeks[date.getDay()],
    };
  });
}

export function genRunTimeStr(runtime) {
  const [h, m] = runtime.split(":");
  return `${h}时${m}分`;
}

export const formatDateShow = (d) => {
  const arr = d.split("-");
  return `${arr[1]}月${arr[2]}日`;
};

export function isTimestampInCurrentMonth(timestamp) {
  const date = new Date(timestamp);
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth(); // 月份是从0开始的

  const inputYear = date.getFullYear();
  const inputMonth = date.getMonth();

  return inputYear === currentYear && inputMonth === currentMonth;
}
/**
 * 是否在x秒内
 * @returns
 */
export function isWithinLastSec(timestamp, sec) {
  const now = Date.now();
  return now - timestamp <= sec * 1000;
}
