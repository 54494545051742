import _defineProperty from "/builds/qMu61Dfa/0/gcp-backend/tea-h5/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import { toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment, renderList as _renderList, withCtx as _withCtx } from "vue";
import _imports_0 from '@/assets/images/right_arrow_gray.svg';
var _hoisted_1 = {
  class: "order-card_header"
};
var _hoisted_2 = {
  class: "name"
};
var _hoisted_3 = {
  class: "order-card_body"
};
var _hoisted_4 = {
  class: "order-card_img"
};
var _hoisted_5 = ["src"];
var _hoisted_6 = {
  class: "order-card_main"
};
var _hoisted_7 = {
  class: "order-card_tc"
};
var _hoisted_8 = {
  class: "type"
};
var _hoisted_9 = {
  key: 0,
  class: "code"
};
var _hoisted_10 = {
  class: "order-card_progress"
};
var _hoisted_11 = {
  class: "highlight"
};
var _hoisted_12 = {
  class: "highlight"
};
var _hoisted_13 = {
  class: "order-card_product-img"
};
var _hoisted_14 = {
  class: "order-card_product-img-box"
};
var _hoisted_15 = {
  class: "order-card_extra"
};
var _hoisted_16 = {
  class: "price"
};
var _hoisted_17 = {
  class: "time"
};
var _hoisted_18 = {
  class: "order-card_btn"
};
import { computed } from "vue";
import { useRouter } from "@/hooks/useRouter";
import { formatDateWithTZ } from "@/utils/format";
import { priceUtil } from "@op/shared";
import SvgIcon from "@/components/base/SvgIcon.vue";
import { OrderStatusEnum, OrderTypeEnum } from "@/constant/order";
import { useSupplierStore } from "@/store/supplierStore";
import Image from "../base/Image.vue";
import InvoiceTrigger from "@/components/invoice/InvoiceTrigger.vue";
import { isTimestampInCurrentMonth } from "@/utils/dateUtil";
export default {
  __name: 'OrderCard',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  emits: ["refresh"],
  setup: function setup(__props, _ref) {
    var _statusTextMap;
    var __emit = _ref.emit;
    var _useRouter = useRouter(),
      routerPush = _useRouter.routerPush;
    var supplierStore = useSupplierStore();
    var emit = __emit;
    var props = __props;
    var statusTextMap = (_statusTextMap = {}, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_statusTextMap, OrderStatusEnum.TO_PAY, "To Pay"), OrderStatusEnum.TO_ACCEPT, "To Accept"), OrderStatusEnum.PREPARING, "Preparing"), OrderStatusEnum.READY, "Ready"), OrderStatusEnum.COMPLETED, "Completed"), OrderStatusEnum.OUT_OF_DELIVERY, "Delivery"), OrderStatusEnum.CANCELLED, "Cancelled"), OrderStatusEnum.TO_DELIVER_CANCELLED, "Cancelled"), OrderStatusEnum.DELIVERING_CANCELLED, "Cancelled"), OrderStatusEnum.READY_CANCELLED, "Cancelled"), _defineProperty(_defineProperty(_statusTextMap, OrderStatusEnum.REJECT_CANCELLED, "Cancelled"), OrderStatusEnum.REFUNDED, "Refunded"));
    var isDone = computed(function () {
      return [OrderStatusEnum.COMPLETED, OrderStatusEnum.CANCELLED, OrderStatusEnum.TO_DELIVER_CANCELLED, OrderStatusEnum.DELIVERING_CANCELLED, OrderStatusEnum.READY_CANCELLED, OrderStatusEnum.REJECT_CANCELLED, OrderStatusEnum.REFUNDED].includes(props.item.delivery_status);
    });
    var isInvoice = computed(function () {
      return supplierStore.getInvoiceSelfServiceEnable(props.item.shop_info.shop_id) && props.item.delivery_status === OrderStatusEnum.COMPLETED && isTimestampInCurrentMonth(props.item.create_at);
    });
    var isCancelled = computed(function () {
      return [OrderStatusEnum.CANCELLED, OrderStatusEnum.TO_DELIVER_CANCELLED, OrderStatusEnum.DELIVERING_CANCELLED, OrderStatusEnum.READY_CANCELLED, OrderStatusEnum.REJECT_CANCELLED].includes(props.item.delivery_status);
    });
    var isPickup = computed(function () {
      return props.item.delivery_method === OrderTypeEnum.SELF_PICK_UP;
    });
    var genCupText = function genCupText(status) {
      if (status === OrderStatusEnum.TO_PAY) {
        return "in store";
      }
      return "before your order";
    };
    var toDetail = function toDetail() {
      if (props.item.delivery_status === OrderStatusEnum.TO_PAY) {
        routerPush({
          name: "OrderDetail",
          query: {
            id: props.item.order_id,
            callback: 1
          }
        });
      } else {
        routerPush({
          name: "OrderDetail",
          query: {
            id: props.item.order_id
          }
        });
      }
    };
    var onSuccess = function onSuccess(data) {
      emit("refresh", props.item.order_id, data);
    };
    return function (_ctx, _cache) {
      var _props$item$shop_inf, _props$item$wait_inf, _props$item$wait_inf2, _props$item$invoice_;
      return _openBlock(), _createElementBlock("div", {
        class: "order-card",
        onClick: toDetail
      }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createTextVNode(_toDisplayString((_props$item$shop_inf = __props.item.shop_info) === null || _props$item$shop_inf === void 0 ? void 0 : _props$item$shop_inf.shop_name) + " ", 1), _createVNode(SvgIcon, {
        name: "arrow_right",
        size: "16",
        class: "icon-arrow-right"
      })]), _createElementVNode("div", {
        class: _normalizeClass(["status", {
          done: isDone.value
        }])
      }, _toDisplayString(statusTextMap[__props.item.delivery_status]), 3)]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_unref(supplierStore).getSupplierPickupImage && _unref(supplierStore).getSupplierDeliveryImage ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        class: "img",
        src: isPickup.value ? _unref(supplierStore).getSupplierPickupImage : _unref(supplierStore).getSupplierDeliveryImage,
        alt: ""
      }, null, 8, _hoisted_5)) : (_openBlock(), _createBlock(SvgIcon, {
        key: 1,
        class: "img",
        name: isPickup.value ? 'pickup' : 'delivery'
      }, null, 8, ["name"]))]), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, _toDisplayString(isPickup.value ? "Pickup" : "Delivery"), 1), __props.item.meal_number ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(__props.item.meal_number), 1)) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_10, [__props.item.delivery_status === _unref(OrderStatusEnum).OUT_OF_DELIVERY ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_createTextVNode(" Please wait for arrival ")], 64)) : __props.item.delivery_status === _unref(OrderStatusEnum).READY ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 1
      }, [_createTextVNode(_toDisplayString(isPickup.value ? "Please pickup the meal" : "Please wait for delivery"), 1)], 64)) : __props.item.delivery_status === _unref(OrderStatusEnum).COMPLETED ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 2
      }, [_createTextVNode(" You order is complete ")], 64)) : isCancelled.value ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 3
      }, [_createTextVNode(" You order has been canceled ")], 64)) : !isDone.value ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 4
      }, [_createElementVNode("span", _hoisted_11, _toDisplayString((_props$item$wait_inf = __props.item.wait_info) === null || _props$item$wait_inf === void 0 ? void 0 : _props$item$wait_inf.order_count), 1), _cache[0] || (_cache[0] = _createTextVNode(" orders / ")), _createElementVNode("span", _hoisted_12, _toDisplayString((_props$item$wait_inf2 = __props.item.wait_info) === null || _props$item$wait_inf2 === void 0 ? void 0 : _props$item$wait_inf2.dishes_count), 1), _createTextVNode(" cups " + _toDisplayString(genCupText(__props.item.delivery_status)), 1)], 64)) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_13, [_createElementVNode("div", _hoisted_14, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.item.dishes, function (dish) {
        return _openBlock(), _createBlock(Image, {
          key: dish.name,
          class: "product-img",
          src: dish.image,
          alt: "",
          "oss-stylename": "type_3"
        }, null, 8, ["src"]);
      }), 128)), _createElementVNode("span", null, "x" + _toDisplayString(__props.item.dishes.length), 1)]), _cache[1] || (_cache[1] = _createElementVNode("div", null, [_createElementVNode("img", {
        class: "icon",
        src: _imports_0,
        alt: ""
      })], -1))]), _createElementVNode("div", _hoisted_15, [_createElementVNode("div", _hoisted_16, _toDisplayString(_unref(priceUtil).formatBaseUnitToShowPrice(__props.item.total_price, __props.item.currency)), 1), _createElementVNode("div", _hoisted_17, _toDisplayString(_unref(formatDateWithTZ)(__props.item.create_at)), 1)])])]), isInvoice.value ? (_openBlock(), _createBlock(InvoiceTrigger, {
        key: 0,
        class: "order-card_btn-container",
        "order-id": __props.item.order_id,
        "shop-id": __props.item.shop_info.shop_id,
        "record-id": (_props$item$invoice_ = __props.item.invoice_info) === null || _props$item$invoice_ === void 0 ? void 0 : _props$item$invoice_.id,
        "order-type": 'delivery',
        onSuccess: onSuccess
      }, {
        default: _withCtx(function () {
          var _props$item$invoice_2;
          return [_createElementVNode("div", _hoisted_18, _toDisplayString((_props$item$invoice_2 = __props.item.invoice_info) !== null && _props$item$invoice_2 !== void 0 && _props$item$invoice_2.id ? "View Invoice" : "Issue Invoice"), 1)];
        }),
        _: 1
      }, 8, ["order-id", "shop-id", "record-id"])) : _createCommentVNode("", true)]);
    };
  }
};