import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { InvoiceStatusTextMap, InvoiceStatusEnum } from "@/constant/invoice.js";
export default {
  __name: 'InvoiceStatusTag',
  props: {
    value: {
      type: String,
      default: ""
    }
  },
  setup: function setup(__props) {
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("span", {
        class: _normalizeClass(["invoice-status", {
          success: __props.value === _unref(InvoiceStatusEnum).INVOICED,
          warning: __props.value === _unref(InvoiceStatusEnum).UNDER_REVIEW
        }])
      }, _toDisplayString(_unref(InvoiceStatusTextMap)[__props.value]), 3);
    };
  }
};