import { Storage } from "@/utils/storageUtil";

export class Persistent {
  static getLocal(key) {
    return Storage.get(key);
  }

  static setLocal(key, value, expire) {
    Storage.set(key, value, expire);
  }

  static removeLocal(key) {
    Storage.remove(key);
  }

  static clearLocal() {
    Storage.clear();
  }

  static getSession(key) {
    return Storage.session.get(key);
  }

  static setSession(key, value) {
    Storage.session.set(key, value);
  }

  static removeSession(key) {
    Storage.session.remove(key);
  }

  static clearSession() {
    Storage.session.clear();
  }
}
